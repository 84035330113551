import React from 'react'

export default function Confirmation() {
  return (
    <div className='container-fluid text-center my-5'>
        <h3>Thank You!!!</h3>
        <h4>Your Order Has Been Placed!!!</h4>
        <h4>Now You Can Track Your Order in Profile Page</h4>

    </div>
  )
}
