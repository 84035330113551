//Maincategory 
export const ADD_MAINCATEGORY="ADD_MAINCATEGORY"
export const ADD_MAINCATEGORY_RED="ADD_MAINCATEGORY_RED"
export const GET_MAINCATEGORY="GET_MAINCATEGORY"
export const GET_MAINCATEGORY_RED="GET_MAINCATEGORY_RED"
export const UPDATE_MAINCATEGORY="UPDATE_MAINCATEGORY"
export const UPDATE_MAINCATEGORY_RED="UPDATE_MAINCATEGORY_RED"
export const DELETE_MAINCATEGORY="DELETE_MAINCATEGORY"
export const DELETE_MAINCATEGORY_RED="DELETE_MAINCATEGORY_RED"


// Subcategory 
export const ADD_SUBCATEGORY="ADD_SUBCATEGORY"
export const ADD_SUBCATEGORY_RED="ADD_SUBCATEGORY_RED"
export const GET_SUBCATEGORY="GET_SUBCATEGORY"
export const GET_SUBCATEGORY_RED="GET_SUBCATEGORY_RED"
export const UPDATE_SUBCATEGORY="UPDATE_SUBCATEGORY"
export const UPDATE_SUBCATEGORY_RED="UPDATE_SUBCATEGORY_RED"
export const DELETE_SUBCATEGORY="DELETE_SUBCATEGORY"
export const DELETE_SUBCATEGORY_RED="DELETE_SUBCATEGORY_RED"

// brand
export const ADD_BRAND="ADD_BRAND"
export const ADD_BRAND_RED="ADD_BRAND_RED"
export const GET_BRAND="GET_BRAND"
export const GET_BRAND_RED="GET_BRAND_RED"
export const UPDATE_BRAND="UPDATE_BRAND"
export const UPDATE_BRAND_RED="UPDATE_BRAND_RED"
export const DELETE_BRAND="DELETE_BRAND"
export const DELETE_BRAND_RED="DELETE_BRAND_RED"


// Product
export const ADD_PRODUCT="ADD_PRODUCT"
export const ADD_PRODUCT_RED="ADD_PRODUCT_RED"
export const GET_PRODUCT="GET_PRODUCT"
export const GET_PRODUCT_RED="GET_PRODUCT_RED"
export const UPDATE_PRODUCT="UPDATE_PRODUCT"
export const UPDATE_PRODUCT_RED="UPDATE_PRODUCT_RED"
export const DELETE_PRODUCT="DELETE_PRODUCT"
export const DELETE_PRODUCT_RED="DELETE_PRODUCT_RED"

// User
export const ADD_USER="ADD_USER"
export const ADD_USER_RED="ADD_USER_RED"
export const GET_USER="GET_USER"
export const GET_USER_RED="GET_USER_RED"
export const UPDATE_USER="UPDATE_USER"
export const UPDATE_USER_RED="UPDATE_USER_RED"
export const DELETE_USER="DELETE_USER"
export const DELETE_USER_RED="DELETE_USER_RED"


// Cart
export const ADD_CART="ADD_CART"
export const ADD_CART_RED="ADD_CART_RED"
export const GET_CART="GET_CART"
export const GET_CART_RED="GET_CART_RED"
export const UPDATE_CART="UPDATE_CART"
export const UPDATE_CART_RED="UPDATE_CART_RED"
export const DELETE_CART="DELETE_CART"
export const DELETE_CART_RED="DELETE_CART_RED"


// Wishlist
export const ADD_WISHLIST="ADD_WISHLIST"
export const ADD_WISHLIST_RED="ADD_WISHLIST_RED"
export const GET_WISHLIST="GET_WISHLIST"
export const GET_WISHLIST_RED="GET_WISHLIST_RED"
export const UPDATE_WISHLIST="UPDATE_WISHLIST"
export const UPDATE_WISHLIST_RED="UPDATE_WISHLIST_RED"
export const DELETE_WISHLIST="DELETE_WISHLIST"
export const DELETE_WISHLIST_RED="DELETE_WISHLIST_RED"

// checkout
export const ADD_CHECKOUT="ADD_CHECKOUT"
export const ADD_CHECKOUT_RED="ADD_CHECKOUT_RED"
export const GET_CHECKOUT="GET_CHECKOUT"
export const GET_CHECKOUT_RED="GET_CHECKOUT_RED"
export const UPDATE_CHECKOUT="UPDATE_CHECKOUT"
export const UPDATE_CHECKOUT_RED="UPDATE_CHECKOUT_RED"
export const DELETE_CHECKOUT="DELETE_CHECKOUT"
export const DELETE_CHECKOUT_RED="DELETE_CHECKOUT_RED"

// Contact
export const ADD_CONTACT="ADD_CONTACT"
export const ADD_CONTACT_RED="ADD_CONTACT_RED"
export const GET_CONTACT="GET_CONTACT"
export const GET_CONTACT_RED="GET_CONTACT_RED"
export const UPDATE_CONTACT="UPDATE_CONTACT"
export const UPDATE_CONTACT_RED="UPDATE_CONTACT_RED"
export const DELETE_CONTACT="DELETE_CONTACT"
export const DELETE_CONTACT_RED="DELETE_CONTACT_RED"

// Newslatter
export const ADD_NEWSLATTER="ADD_NEWSLATTER"
export const ADD_NEWSLATTER_RED="ADD_NEWSLATTER_RED"
export const GET_NEWSLATTER="GET_NEWSLATTER"
export const GET_NEWSLATTER_RED="GET_NEWSLATTER_RED"
export const DELETE_NEWSLATTER="DELETE_NEWSLATTER"
export const DELETE_NEWSLATTER_RED="DELETE_NEWSLATTER_RED"